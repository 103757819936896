/*body {*/
/*  margin: 0;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  height: 100%;*/
/*  margin: 0;*/
/*  background-color: #282c34;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: white;*/
/*}*/
/**/
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  height: 100%;
  margin: 0;
  background-color: #282c34;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #282c34;
}
.header,
.footer {
}
.content {
  flex: 1;
  overflow: auto;
  padding: 5px 10px;
}
