.image-container img {
  max-width: 100%;
  cursor: pointer;
}

.overlay {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
}

.fullscreen-image {
  transition:
    transform 0.3s ease,
    top 0.3s ease,
    left 0.3s ease;
  cursor: pointer;
  position: absolute;
  /*top: 50%;*/
  /*left: 50%;*/
  /*transform: translate(-50%, -50%);*/
}

.overlay.zoomed .fullscreen-image {
  cursor: grab;
}
